<template>
  <div>
    <v-card
      class="rounded-lg shadow"
      :loading="isLoading"
      :disabled="isLoading"
      elevation="0"
    >
      <v-card-text class="p-3">
        <v-skeleton-loader v-if="isLoading" type="table" />

        <div v-else>
          <div class="d-flex justify-space-between mb-3">

          <div>
            <v-text-field
                    v-model="filter.keyword"
                    rounded
                    filled
                    dense
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                    clearable
                    placeholder="Rechercher..."
                    v-on:keyup.enter="getItems"
            />

          </div>

            <v-btn  depressed
                    rounded
                    color="primary"
                    @click="addCategory">
              <v-icon left>mdi-plus</v-icon>
              Ajouter
            </v-btn>
          </div>

          <v-divider />

          <v-simple-table elevation="0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>ID</th>
                  <th class="text-center">Icone</th>
                  <th>Titre</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in categories" :key="item.id">
                  <td>
                    {{ item.id }}
                  </td>
                  <td style="max-width: 50px" class="text-center">
                    <v-img
                      :src="getImageLink(item.image)"
                      max-height="40"
                      contain
                      class="mt-2"
                      v-if="item.image != null"
                    ></v-img>
                  </td>
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    <v-menu transition="slide-x-transition" bottom right>
                      <v-list dense>
                        <v-list-item @click="updateCategory(item)">
                          <v-list-item-icon>
                            <v-icon color="black">mdi-pencil</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Modifier</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="deleteCategory(item)">
                          <v-list-item-icon>
                            <v-icon color="red">mdi-delete</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Supprimer</v-list-item-title>
                        </v-list-item>
                      </v-list>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="black" icon dark v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card-text>
    </v-card>
    <AddCategory ref="AddCategory" @refresh="getItems" />
    <UpdateCategory ref="UpdateCategory" @refresh="getItems" />
    <DeleteDialog ref="deleteCategory" @confirmed="removeCategory" />
  </div>
</template>

<script>
import AddCategory from "./components/AddCategory.vue";
import UpdateCategory from "./components/UpdateCategory.vue";
import DeleteDialog from "./components/DeleteDialog.vue";

import { HTTP } from "@/http-common";

export default {
  components: { AddCategory, UpdateCategory, DeleteDialog },
  data() {
    return {
      delete_id: null,
      filter: {},
      isLoading: false,
      categories: [],
    };
  },
  methods: {
    getItems() {
      this.isLoading = true;

      HTTP.get("v1/users/categories")
        .then((res) => {
          this.categories = res.data.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    addCategory() {
      this.$refs.AddCategory.open();
    },
    updateCategory(item) {
      this.$refs.UpdateCategory.open(item);
    },
    deleteCategory(item) {
      this.delete_id = item.id;
      this.$refs.deleteCategory.open();
    },
    removeCategory() {
      HTTP.delete("v1/users/categories/" + this.delete_id)
        .then(() => {
          this.$successMessage = "Cette Catégorie a été supprimée";
          this.getItems();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>