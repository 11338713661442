<template>
    <div>
      <v-card
        class="rounded-lg shadow"
        :loading="isLoading"
        :disabled="isLoading"
        elevation="0"
      >
        <v-card-text class="p-3">
          <v-skeleton-loader v-if="isLoading" type="table" />
  
          <div v-else>

            <div class="d-flex justify-space-between mb-3">

              <div>
                <v-text-field
                        v-model="filter.keyword"
                        rounded
                        filled
                        dense
                        append-icon="mdi-magnify"
                        single-line
                        hide-details
                        clearable
                        placeholder="Rechercher..."
                        v-on:keyup.enter="getItems"
                />

              </div>

              <v-btn  depressed
                      rounded
                      color="primary"
                      @click="addType">
                <v-icon left>mdi-plus</v-icon>
                Ajouter
              </v-btn>
            </div>

            <v-divider />
  
            <v-simple-table elevation="0">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nom</th>
                    <th>Nom en arabe</th>
                    <th>Categorie</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in types" :key="item.id">
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.name }}
                    </td>
                    <td>
                      {{ item.name_ar }}
                    </td>
                    <td>
                      {{ item.category ?  item.category.name : "" }}
                    </td>
                    <td>
                      <v-menu transition="slide-x-transition" bottom right>
                        <v-list dense>
                          <v-list-item @click="updateType(item)">
                            <v-list-item-icon>
                              <v-icon color="black">mdi-pencil</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Modifier</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="deleteType(item)">
                            <v-list-item-icon>
                              <v-icon color="red">mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Supprimer</v-list-item-title>
                          </v-list-item>
                        </v-list>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="black" icon dark v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                      </v-menu>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card-text>
      </v-card>
      <!-- <AddType ref="AddType" @refresh="getItems" />
      <UpdateType ref="UpdateType" @refresh="getItems" />-->
      <deleteDialog ref="deleteType" @confirmed="removeType" />
      <SalepointTypeForm @refresh="getItems" ref="formType" />
    </div>
  </template>
  
  <script>

  import SalepointTypeForm from "./components/SalepointTypeForm.vue";
import deleteDialog from "./components/deleteDialog.vue";

  import { HTTP } from "@/http-common";
  
  export default {
    components: { SalepointTypeForm, deleteDialog },
    data() {
      return {
        delete_id: null,
        filter: {},
        isLoading: false,
        types: [],
      };
    },
    methods: {
      getItems() {
        this.isLoading = true;
  
        HTTP.get("v1/salepoints/types")
          .then((res) => {
            this.types = res.data;
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            console.log(err);
          });
      },
      addType() {
        this.$refs.formType.open();
      },
      updateType(item) {
        this.$refs.formType.open(item);
      },
      deleteType(item) {
        this.delete_id = item.id;
        this.$refs.deleteType.open();
      },
      removeType() {
        HTTP.delete("v1/salepoints/types/" + this.delete_id)
          .then(() => {
            this.$successMessage = "Ce Type a été supprimé";
            this.getItems();
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  };
  </script>