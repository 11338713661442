<template>
    <div>
      <v-dialog v-model="dialog" max-width="400" persistent>
        <v-card class="text-center">
          <v-card-text class="pa-6">
            <v-icon color="primary" size="80" class="animate__animated animate__rotateIn">
                mdi-information-outline
            </v-icon>
             <h2 class="text--primary mt-3">Supprimer un Type</h2>
             <p class="mt-5 mb-5"> Voulez-vous vraiment supprimer ce Type ?</p>
            <v-btn text class="rounded-lg mr-2 text-none" large  @click="notConfirmed()">
              <v-icon left>mdi-close</v-icon>  Annuler
            </v-btn>
            <v-btn  class="rounded-lg text-none" large color="primary" depressed
                   @click="confirm()">
              <v-icon left>mdi-check-circle-outline</v-icon> Confirmer
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        dialog: false,
        is_confirm: false,
        confirm_dialog: false
      }
    },
    methods: {
        open(){
          this.dialog = true
        },
        confirm(){
            this.$emit('confirmed')
            this.dialog = false;
        },
        notConfirmed(){
            this.dialog = false;
        }
    }
  }
  </script>