<template>
  <div>
    <v-dialog v-model="openDialog" max-width="600" persistent>
      <v-card>
        <v-card-title>
          Modifier une catégorie
          <v-spacer />
          <v-btn icon class="v-btn--active" color="primary" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-6">
          <form @submit.prevent="send">
            <v-text-field
              label="Désignation *"
              @input="
                formErrors && formErrors.name ? (formErrors.name = '') : false
              "
              :error-messages="
                formErrors && formErrors.name ? formErrors.name[0] : ''
              "
              v-model="form.name"
            />

            <v-text-field
                label="Désignation en Arabe *"
                @input="
                  formErrors && formErrors.name_ar ? (formErrors.name_ar = '') : false
                "
                :error-messages="
                  formErrors && formErrors.name_ar ? formErrors.name_ar[0] : ''
                "
                v-model="form.name_ar"
              />

            <v-textarea
              label="Description"
              @input="
                formErrors && formErrors.description
                  ? (formErrors.description = '')
                  : false
              "
              :error-messages="
                formErrors && formErrors.description
                  ? formErrors.description[0]
                  : ''
              "
              v-model="form.description"
            >
            </v-textarea>

            <v-textarea
                label="Description en arabe"
                @input="
                  formErrors && formErrors.description_ar
                    ? (formErrors.description_ar = '')
                    : false
                "
                :error-messages="
                  formErrors && formErrors.description_ar
                    ? formErrors.description_ar[0]
                    : ''
                "
                v-model="form.description_ar"
              >
              </v-textarea>

            <div class="d-flex align-center">
              <v-file-input
                label="Icone *"
                accept="image/png, image/jpeg, image/jpg, .svg, .gif"
                show-size
                suffix="Image"
                @change="uploadFile"
                @focus="
                  formErrors && formErrors.image
                    ? (formErrors.image = '')
                    : false
                "
                ref="fileIcon"
                chips
                prepend-icon="mdi-camera"
                :error-messages="formErrors.image"
              />
              <v-btn
                depressed
                icon
                small
                class="primary white--text ml-2"
                v-if="form.image"
                @click="previewImage(form.image)"
              >
                <v-icon small>mdi mdi-eye</v-icon>
              </v-btn>
            </div>

            <div
              v-if="item && item.image"
              class="d-flex justify-space-between pa-3 mb-2 rounded"
              :class="
                $vuetify.theme.dark ? 'secondary lighten-1' : 'grey lighten-3'
              "
            >
              <v-avatar size="40">
                <v-img
                  :src="getImageLink(item.image)"
                  class="rounded zoom-pointer"
                />
              </v-avatar>
              <v-spacer />
              <v-icon color="success">mdi-check-circle</v-icon>
            </div>

            <v-btn
              color="primary"
              class="rounded-lg text-none mt-3"
              :loading="btnLoading"
              large
              depressed
              @click="send"
            >
              <v-icon left>mdi-send-outline</v-icon>
              Savegarder
            </v-btn>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
    
<script>
import { HTTP } from "@/http-common";

export default {
  data() {
    return {
      btnLoading: false,

      formErrors: {},
      form: {
        name: "",
        name_ar: "",
        image: "",
        description: "",
        description_ar: "",
      },

      openDialog: false,
      item: null,
    };
  },

  computed: {},
  methods: {
    open(item) {
      
      if(this.$refs.fileIcon){
        this.$refs.fileIcon.reset();
      }
      
      this.category_id = item.id;

      this.form.name = item.name;
      this.form.name_ar = item.name_ar;
      this.form.description = item.description;
      this.form.description_ar = item.description_ar;
      this.item = item;
      this.openDialog = true;
    },
    close() {
      //this.$emit("update:openDialog", false);
      this.openDialog = false;
    },
    send() {
      this.btnLoading = true;

      let formData = new FormData();
      Object.keys(this.form).forEach((key) => {
        formData.append(key, this.form[key]);
      });

      formData.append("_method", "PUT");

      HTTP.post("/v1/users/categories/" + this.category_id, formData, {
        params: {},
      })
        .then(() => {
          this.$successMessage = "Cette Catégorie été modifiée avec succès";
          this.btnLoading = false;
          this.close();
          this.form = {};
          this.$refs.fileIcon.reset();

          this.openDialog = false;
          this.$emit("refresh");
        })
        .catch((err) => {
          this.btnLoading = false;
          this.formErrors = err.response.data.errors;
        });
    },

    uploadFile(file) {
      this.form.image = file;
    },
  },
  created() {},
};
</script>