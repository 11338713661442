<template>
  <v-container fluid>
    <div class="main">
      <v-row>
        <v-col cols="3">
          <v-card class="rounded-lg" elevation="0">

            <v-card-text>

              <v-btn block color="gifty" exact text to="/users">
                <v-icon left>mdi-arrow-left</v-icon>
                Retour
              </v-btn>

              <v-list nav>
                <v-list-item-group v-model="model" mandatory>

                  <v-list-item v-for="(item, i) in items" :key="i"
                               :value="item.value"
                               active-class="border--primary" class="translateY nav-list" color="primary">
                    <v-list-item-icon>
                      <v-icon color="primary" dense>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>

            </v-card-text>
          </v-card>

        </v-col>
        <v-col cols="9">
          <CategoriesList v-show="model === 1" ref="categoriesList"/>
          <SalePointTypeTypeList v-show="model === 2" ref="salePointTypeTypeList"/>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>


<script>

import CategoriesList from "./categories/CategoriesList.vue"
import SalePointTypeTypeList from "./typeSalepoint/SalePointTypeList.vue";

export default {
    components: {CategoriesList, SalePointTypeTypeList},
    data() {
        return {
            items: [
                {text: 'Catégories', icon: 'mdi-gamepad-circle-down', value: 1},
                {text: 'Types POS', icon: 'mdi-clipboard-text-outline', value: 2},
            ],
            model: 1,

        }
    },
    methods: {},
    computed: {},
    mounted() {
        this.$refs.categoriesList.getItems()
    },
    watch: {
        model(val) {
            switch (val) {
                case 1:
                    return this.$refs.categoriesList.getItems()
                case 2:
                    return this.$refs.salePointTypeTypeList.getItems()
                case 3:
                    return this.$refs.referralCodesList.getItems()
            }
        }
    }
}
</script>