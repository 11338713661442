<template>
  <div>
    <v-dialog v-model="openDialog" max-width="600" persistent>
      <v-card>
        <v-card-title>
          {{ title }}
          <v-spacer />
          <v-btn icon class="v-btn--active" color="primary" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-6">
          <form @submit.prevent="send">
            <v-card-text>
              <v-text-field
                label="Désignation *"
                @input="
                  formErrors && formErrors.name ? (formErrors.name = '') : false
                "
                :error-messages="
                  formErrors && formErrors.name ? formErrors.name[0] : ''
                "
                v-model="form.name"
              />

              <v-text-field
                label="Désignation en Arabe *"
                @input="
                  formErrors && formErrors.name_ar
                    ? (formErrors.name_ar = '')
                    : false
                "
                :error-messages="
                  formErrors && formErrors.name_ar ? formErrors.name_ar[0] : ''
                "
                v-model="form.name_ar"
              />
              <v-select
                label="Catégorie *"
                item-text="name"
                :loading="isCategoryLoading"
                item-value="id"
                :items="categories"
                :error-messages="formErrors.category_id"
                v-model="form.category_id"
              >
                <template v-slot:item="slotProps">
                  <div class="d-flex align-center justify-center">
                    <div style="width: 50px">
                      <img
                        :src="getImageLink(slotProps.item.image)"
                        max-height="25"
                        max-width="25"
                        width="25"
                        height="25"
                        class="p-2"
                      />
                    </div>

                    {{ slotProps.item.name }}
                  </div>
                </template>
              </v-select>
            </v-card-text>

            <v-btn
              color="primary"
              class="rounded-lg text-none mt-3"
              :loading="btnLoading"
              large
              depressed
              @click="save"
            >
              <v-icon left>mdi-send-outline</v-icon>
              Savegarder
            </v-btn>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
    
    <script>
import { HTTP } from "@/http-common";

export default {
  data() {
    return {
      title: null,
      btnLoading: false,
      isCategoryLoading: false,
      formErrors: {},
      categories: [],
      form: {
        id: null,
        name: "",
        name_ar: "",
        category_id: null,
      },
      openDialog: false,
      method: null,
    };
  },

  computed: {},
  methods: {
    open(item) {
      this.openDialog = true;
      if (item) {
        this.title = "Modifier un Type";
        this.form = {
          id: item.id,
          name: item.name,
          name_ar: item.name_ar,
          category_id: item.user_category_id,
        };
      } else {
        this.title = "Nouveau Type";
        this.form = {
          id: null,
        };
      }

      if (!this.categories.length) {
        this.getCategories();
      }
    },
    close() {
      //this.$emit("update:openDialog", false);
      this.openDialog = false;
    },
    save() {
      this.btnLoading = true;

      let formData = new FormData();
      Object.keys(this.form).forEach((key) => {
        formData.append(key, this.form[key]);
      });

      var url = "v1/salepoints/types";

      if(this.form.id != null){
        url += "/"+this.form.id;
      }

      HTTP.post(url, formData)
        .then(() => {
          this.$successMessage = "Enregistrement avec succès";
          this.btnLoading = false;          
          this.$emit("refresh");
          this.openDialog = false;
          
        })
        .catch((err) => {
          this.btnLoading = false;
          if(err.response &&  err.response.data){
            this.formErrors = err.response.data.errors;
          }
          
        });
    },

    getCategories() {
      // /api/v1/users/categories
      this.isCategoryLoading = true;
      HTTP.get("/v1/users/categories")
        .then((res) => {
          this.isCategoryLoading = false;
          this.categories = res.data.data;
        })
        .catch((err) => {
          this.isRoleLoading = false;
          console.log(err);
        });
    },
  },
  created() {
    
  },
};
</script>
    